<template lang="pug">
  .left-menu-mobile-overlay(:class="{'left-menu-mobile-expanded': isExpanded}")
    .left-menu-mobile-wrapper
      .left-menu-mobile-close( @click="menuClose")
        MenuCloseSvg

      .left-menu-mobile-search
        input.left-menu-mobile-search-input(type='search' :placeholder="$t('leftMenuMobile.searchPlaceholder')")

      .left-menu-mobile-scroll
        .left-menu-mobile-items
          details( :class="['left-menu-mobile-item', { selected: pages['root'] }]")
            summary(@click="clickMenu('root')") {{ $t('leftMenuMobile.homeMenu') }} 
          details( :class="['left-menu-mobile-item', { selected: pages['games'] }]")
            summary(@click="clickMenu('games')") {{ $t('leftMenuMobile.gamesMenu') }}
          details( :class="['left-menu-mobile-item', { selected: pages['promo'] }]")
            summary(@click="clickMenu('promo')") {{ $t('leftMenuMobile.promotionsMenu') }}

        .left-menu-mobile-forauth(v-if="isAuthUser")
          .left-menu-mobile-info
            .left-menu-mobile-info-row(:data-title="$t('leftMenuMobile.cashBalanceLabel')" :data-balance="balance")
              .left-menu-mobile-info-btn
            .left-menu-mobile-info-splitter  
            .left-menu-mobile-info-row(:data-title="$t('leftMenuMobile.bonusBalanceLabel')" :data-balance="balanceBonus")

          .left-menu-mobile-items
            details.left-menu-mobile-item.left-menu-mobile-item-more
              summary {{ $t('leftMenuMobile.profileMenu') }}
              .left-menu-mobile-items
                details( :class="['left-menu-mobile-item', { selected: pages['personal'] && openSection === 'editProfile' }]")
                  summary(@click="clickMenu('editProfile')") {{ $t('leftMenuMobile.editProfileMenu') }}
                details( :class="['left-menu-mobile-item', { selected: pages['personal'] && openSection === 'security' }]")
                  summary(@click="clickMenu('security')") {{ $t('leftMenuMobile.securityMenu') }}
                details( :class="['left-menu-mobile-item', { selected: pages['personal'] && openSection === 'settings' }]")
                  summary(@click="clickMenu('settings')") {{ $t('leftMenuMobile.settingsMenu') }}
                details( :class="['left-menu-mobile-item', { selected: false }, 'disabled']")
                  summary(@click="clickMenu('')") {{ $t('leftMenuMobile.knowYourCustomerMenu') }}
                details( :class="['left-menu-mobile-item', { selected: false }, 'disabled']")
                  summary(@click="clickMenu('')") {{ $t('leftMenuMobile.responsibleGamingMenu') }}

            details.left-menu-mobile-item.left-menu-mobile-item-more
              summary {{ $t('leftMenuMobile.paymentsMenu') }}
              .left-menu-mobile-items
                details( :class="['left-menu-mobile-item', { selected: pages['personal'] && openSection === 'deposit' }]")
                  summary(@click="clickMenu('deposit')") {{ $t('leftMenuMobile.depositMenu') }}
                details( :class="['left-menu-mobile-item', { selected: pages['personal'] && openSection === 'withdrawal' }]")
                  summary(@click="clickMenu('withdrawal')") {{ $t('leftMenuMobile.withdrawalMenu') }}
                details( :class="['left-menu-mobile-item', { selected: pages['personal'] && openSection === 'paymentsHistory' }]")
                  summary(@click="clickMenu('paymentsHistory')") {{ $t('leftMenuMobile.historyMenu') }}

      .lfm-line

      .left-menu-mobile-footer
        .left-menu-mobile-logout(v-if="isAuthUser" @click="logoutHandler")
          span {{ $t('leftMenuMobile.logOutButton') }}
        .left-menu-mobile-login(v-if="!isAuthUser" @click="loginHandler")
          span {{ $t('leftMenuMobile.logInButton') }}
        .languages-selector(v-if="brand.is_change_lang")
          LanguageSelector(viewSelector="mobileMenu")
</template>

<script>
import LeftMenuMobile from '../LeftMenuMobile.vue';

const MenuCloseSvg = () => import('@/components/topMenu/MenuCloseSvg');
const LanguageSelector = () => import('@/components/serviceComponents/languageSelector/LanguageSelectorSW');

export default {
  extends: LeftMenuMobile,
  components: {
    LanguageSelector,
    MenuCloseSvg
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/top-menu/sunduk-white/left-menu-mobile_sw.scss";
</style>
