<template lang="pug">
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import convertCurrencyToSymbol from '@/utils/convertCurrencyToSymbol';
import pureMoneyFormat from '@/utils/currency';

export default {
  name: 'LeftMenuMobile',
  data: () => ({
    isExpanded: false,
    moneyCurency: convertCurrencyToSymbol,
    moneyFormat: pureMoneyFormat
  }),
  computed: {
    ...mapGetters({
      userBalance: 'user/getBalance',
      isAuthUser: 'auth/isAuthUser',
      pages: 'main/getPages',
      openSection: 'personal/getOpenSection',
      brand: 'main/getBrand',
    }),

    balance() {
      return this.moneyFormat(
        this.userBalance.value === null ? 0 : this.userBalance.value,
        this.userBalance.separator,
        this.userBalance.currency_minor_unit
      )+
      ' '+
      this.moneyCurency(this.userBalance.currency);
    },
    balanceBonus() {
      return '0 ' + this.moneyCurency(this.userBalance.currency);
    }
  },
  methods: {
    ...mapActions({
      doLogout: 'auth/doLogout',
    }),
    ...mapMutations({
      SET_SIGNIN_VISIBLE: 'auth/SET_SIGNIN_VISIBLE',
      SET_OPEN_SECTION: 'personal/SET_OPEN_SECTION',
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',

    }),
    menuClose () {
      this.isExpanded = false;
    },
    menuToggle () {
      this.isExpanded = !this.isExpanded;
    },
    async logoutHandler() {
      this.menuClose();
      this.doLogout();
      this.SET_OPEN_PAGE('root');
      this.$router.push('/');
      window.scrollTo(0, 0);
    },
    async loginHandler() {
        this.menuClose();
        this.SET_SIGNIN_VISIBLE(true);
    },

    async clickMenu(item_menu = '') {
      switch (item_menu) {
        case 'root':
          if (!this.pages['root']) {
            this.$router.push('/').catch({});
            window.scrollTo(0, 0);
            this.SET_OPEN_PAGE('root');
            this.menuClose();
          }
          break;
        case 'games':
          if (!this.pages['games']) {
            this.$router.push('/games').catch({});
            window.scrollTo(0, 0);
            this.SET_OPEN_PAGE('games');
            this.menuClose();
          }
          break;
        case 'editProfile':
        case 'security':
        case 'settings':
        case 'deposit':
        case 'withdrawal':
        case 'paymentsHistory':
          if (window.location.pathname !== '/personal') {
            this.$router.push('/personal').catch({});
          }
          await this.SET_OPEN_SECTION(item_menu);
          this.SET_OPEN_PAGE('personal');

          this.menuClose();
          break;
        default:
          console.log('Нет страницы: "' + item_menu + '"');
          break;
      }
    }
  },
    
  mounted() {
      window.addEventListener(
        'message',
        (event) => {
            if (
              event.origin === window.location.origin &&
              event.data === 'LeftMenuMobile'
            )
                this.menuToggle(); 
        },
        false
      );
  }
};
</script>

<style lang="scss">
</style>
